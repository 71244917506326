@layer modules, ui, base;
@layer ui {
  @keyframes sk-Breadcrumb_appear__7OHF_ {
  to {
    opacity: 1;
  }
}

.sk-Breadcrumb_main__3GL8W {
  --Breadcrumb-separator-icon-body2-size: var(--sk-size-16);
  --Breadcrumb-separator-icon-size: var(--Breadcrumb-separator-icon-body2-size);
  --Breadcrumb-separator-icon-data: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy41ODEzIDQuMTg2MjJDMTguMDMwNyA0LjUwNzIzIDE4LjEzNDggNS4xMzE3OCAxNy44MTM3IDUuNTgxMTlMNy44MTM3NSAxOS41ODEyQzcuNDkyNzQgMjAuMDMwNiA2Ljg2ODE5IDIwLjEzNDcgNi40MTg3OCAxOS44MTM3QzUuOTY5MzcgMTkuNDkyNyA1Ljg2NTI3IDE4Ljg2ODEgNi4xODYyOCAxOC40MTg3TDE2LjE4NjMgNC40MTg3MkMxNi41MDczIDMuOTY5MyAxNy4xMzE4IDMuODY1MjEgMTcuNTgxMyA0LjE4NjIyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");

  box-sizing: border-box;
}

/**
 * Hack to display title attribute in mobile
 */

.sk-Breadcrumb_main__3GL8W [title]:active::before {
  content: attr(title);
  display: inline-block;
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.35rem 0.55rem;
  transform: translateY(-115%);
  animation: sk-Breadcrumb_appear__7OHF_ 500ms step-end forwards;
  border-radius: 2px;
  outline: solid 2px rgba(56, 56, 57, 0.8);
  opacity: 0;
  background-color: rgb(56, 56, 57);
  color: var(--sk-color-white);
  font-size: var(--IconButton-title-font-size);
  white-space: nowrap;
}

.sk-Breadcrumb_breadcrumb__Iv341 {
  display: inline-grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  margin: unset;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
}

.sk-Breadcrumb_item__vuXEV {
  display: none;
  padding: var(--sk-space-4) var(--sk-space-2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Breadcrumb_item__vuXEV:first-child,
.sk-Breadcrumb_item__vuXEV:last-child,
.sk-Breadcrumb_item--ellipsis__sF1M2 {
  display: list-item;
}

.sk-Breadcrumb_item--ellipsis__sF1M2 {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Breadcrumb_item__vuXEV:not(:first-child)::before {
  content: "";
  display: inline-flex;
  width: var(--Breadcrumb-separator-icon-size);
  height: var(--Breadcrumb-separator-icon-size);
  margin-right: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  vertical-align: sub;
  -webkit-mask-image: var(--Breadcrumb-separator-icon-data);
  mask-image: var(--Breadcrumb-separator-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Breadcrumb_link__k6kBu {
  margin-top: calc(var(--sk-space-2) * -1);
  margin-bottom: calc(var(--sk-space-2) * -1);
  padding-top: var(--sk-space-2);
  padding-bottom: var(--sk-space-2);
}

.sk-Breadcrumb_main--light__7f1DQ {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Breadcrumb_main--light__7f1DQ .sk-Breadcrumb_item__vuXEV::before {
  background-color: var(--sk-navigation-link-color-default-light);
}

/* Fix ellipsis color */
.sk-Breadcrumb_main--light__7f1DQ .sk-Breadcrumb_item__vuXEV:has(.sk-Breadcrumb_link__k6kBu:hover) {
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Breadcrumb_main--dark__jeKYB {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Breadcrumb_main--dark__jeKYB .sk-Breadcrumb_item__vuXEV::before {
  background-color: var(--sk-navigation-link-color-default-dark);
}

/* Fix ellipsis color */
.sk-Breadcrumb_main--dark__jeKYB .sk-Breadcrumb_item__vuXEV:has(.sk-Breadcrumb_link__k6kBu:hover) {
  color: var(--sk-navigation-link-color-hover-dark);
}

@media only screen and (min-width: 480px) {
  .sk-Breadcrumb_item__vuXEV:not(.sk-Breadcrumb_item--hidden__U3YfJ) {
    display: list-item;
  }

  .sk-Breadcrumb_item--hidden__U3YfJ,
  .sk-Breadcrumb_main__3GL8W [title]:active::before {
    display: none;
  }
}

}
@layer base {
  .DropdownContent_main__bodyText__B7zat {
  font-size: var(--sk-typography-body-1-regular-font-size);
  font-style: normal;
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.DropdownContent_main__dropdownContent__LQQZo {
  padding: var(--sk-space-8) var(--sk-space-16);
  color: var(--sk-color-grey-500);
  font-family: var(--sk-font-families-poppins);

  text-align: center;
}

.DropdownContent_main__dropdownItem__XseAn {
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border: none;
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-poppins);
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.DropdownContent_main__dropdownItem__XseAn:hover,
.DropdownContent_main__dropdownItem__XseAn:focus,
.DropdownContent_main__dropdownItem__XseAn:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
  cursor: pointer;
}

.DropdownContent_main__dropdownAbroadOption__ytDdL {
  display: flex;
  gap: 8px;
  align-items: center;
}

.DropdownContent_main__error__kHScH {
  color: var(--sk-color-error-500);
}

.DropdownContent_main__abroadIcon__omGm0 {
  width: var(--sk-space-20);
  height: var(--sk-space-20);
}
}
@layer base {
  .PageContent_main__0Btg7 {
  --local-row-gap: var(--sk-space-40) !important;
  margin-top: var(--sk-space-40);

  margin-bottom: var(--sk-space-40);
}

@media (min-width: 768px) {
  .PageContent_main__0Btg7 {
    --local-row-gap: var(--sk-space-80) !important;
    margin-top: var(--sk-size-88);

    margin-bottom: var(--sk-size-88);
  }
}

/* Cards */
.PageContent_cards__HZcQn {
  display: flex;
  flex-flow: column wrap;
  gap: var(--sk-space-16);
}

.PageContent_card__vNeF7 {
  margin: 0;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}

@media (min-width: 768px) {
  .PageContent_cards__HZcQn {
    flex-direction: row;
    align-content: space-between;
    gap: var(--sk-space-24);
  }

  .PageContent_card__vNeF7 {
    flex: 1;
  }
}

/* Header */
.PageContent_header__Zs75b {
  text-align: center;
}

.PageContent_header__Zs75b .PageContent_title__cgyx2 {
  margin-bottom: var(--sk-space-16);
}

.PageContent_header__Zs75b .PageContent_subtitle__1qCQM {
  margin-bottom: var(--sk-space-16);
}

.PageContent_header__Zs75b .PageContent_link__RkF3o {
  margin-top: var(--sk-space-24);
}

/* Figures */
.PageContent_figures__2VECq {
  margin: var(--sk-space-40) 0 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.PageContent_figures__2VECq strong {
  display: block;
}

/* Positions */
.PageContent_positions__5u_VZ .PageContent_list__7eJ__ {
  --local-column-count: 1;
  --local-gap: var(--sk-space-24);

  display: grid;
  grid-template-columns: repeat(var(--local-column-count), 1fr);

  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
  gap: var(--local-gap);
}

/* 480px */

@media (min-width: 30.001rem) {
  .PageContent_positions__5u_VZ .PageContent_list__7eJ__ {
    grid-template-columns: repeat(2, minmax(calc(50% - var(--local-gap)), 1fr));
  }
}

@media (min-width: 768px) {
  .PageContent_positions__5u_VZ .PageContent_list__7eJ__ {
    grid-template-columns: repeat(3, minmax(calc(33% - var(--local-gap)), 1fr));
  }
}

.PageContent_companyCard__q0QL8 {
  min-width: auto;
  height: 100%;
}

.PageContent_companyCard__q0QL8 .PageContent_head__hD589 {
  align-items: center;
  text-align: center;
}

.PageContent_companyCard__q0QL8 a {
  font-size: var(--sk-typography-body-1-semibold-font-size);
  line-height: var(--sk-typography-body-1-semibold-line-height);
}

/* Fun Fact */
.PageContent_funFact__NsDpR {
  position: relative;
  padding: var(--sk-space-32) var(--sk-space-48);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-purple-500);
  color: var(--sk-color-white);
}

.PageContent_funFact__NsDpR::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--sk-size-48);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xhc3M9ImxheWVyIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xNyw4Yy0wLjI3LDAgLTAuNTIsMC4xMSAtMC43MSwwLjI5Yy0wLjE4LDAuMTkgLTAuMjksMC40NCAtMC4yOSwwLjcxbDAsMWMwLDEuMzMgLTAuNTMsMi42IC0xLjQ2LDMuNTRjLTAuNywwLjY5IC0xLjU5LDEuMTYgLTIuNTQsMS4zNmwwLDUuMWMwLDAuNTMgMC4yMSwxLjA0IDAuNTksMS40MWMwLjM3LDAuMzggMC44OCwwLjU5IDEuNDEsMC41OWw3LDBjMC4wMywwIDAuMDYsMCAwLjA4LDBjMC4wOSwwLjAxIDAuMjQsLTAuMDEgMC40NCwtMC4yMWMwLjE5LC0wLjIgMC4zOSwtMC41NCAwLjUsLTFsMC45OCwtNC44N2MtMC4wMiwtMC4yNCAtMC4xMiwtMC40NiAtMC4yOSwtMC42M2MtMC4xOSwtMC4xOCAtMC40NCwtMC4yOSAtMC43MSwtMC4yOWwtMywwYy0wLjU1LDAgLTEsLTAuNDUgLTEsLTFsMCwtNWMwLC0wLjI3IC0wLjExLC0wLjUyIC0wLjI5LC0wLjcxYy0wLjE5LC0wLjE4IC0wLjQ0LC0wLjI5IC0wLjcxLC0wLjI5em0tNS40LDE1LjJjLTAuMDYsMC4wOCAtMC4xMiwwLjE1IC0wLjE5LDAuMjFjLTAuMzcsMC4zOCAtMC44OCwwLjU5IC0xLjQxLDAuNTlsLTIsMGMtMC41MywwIC0xLjA0LC0wLjIxIC0xLjQxLC0wLjU5Yy0wLjM4LC0wLjM3IC0wLjU5LC0wLjg4IC0wLjU5LC0xLjQxbDAsLTdjMCwtMC41MyAwLjIxLC0xLjA0IDAuNTksLTEuNDFjMC4zNywtMC4zOCAwLjg4LC0wLjU5IDEuNDEsLTAuNTlsMywwYzAuOCwwIDEuNTYsLTAuMzIgMi4xMiwtMC44OGMwLjU2LC0wLjU2IDAuODgsLTEuMzIgMC44OCwtMi4xMmwwLC0xYzAsLTAuOCAwLjMyLC0xLjU2IDAuODgsLTIuMTJjMC41NiwtMC41NiAxLjMyLC0wLjg4IDIuMTIsLTAuODhjMC44LDAgMS41NiwwLjMyIDIuMTIsMC44OGMwLjU2LDAuNTYgMC44OCwxLjMyIDAuODgsMi4xMmwwLDRsMiwwYzAuOCwwIDEuNTYsMC4zMiAyLjEyLDAuODhjMC41NiwwLjU2IDAuODgsMS4zMiAwLjg4LDIuMTJjMCwwLjA3IC0wLjAxLDAuMTMgLTAuMDIsMC4ybC0xLDVjMCwwLjAxIDAsMC4wMiAtMC4wMSwwLjAzYy0wLjE3LDAuNzUgLTAuNTIsMS40NiAtMS4wNCwxLjk4Yy0wLjUyLDAuNTIgLTEuMjEsMC44NCAtMS45NywwLjc5bC02Ljk2LDBjLTAuODcsMCAtMS43MSwtMC4yOCAtMi40LC0wLjh6bS0xLjYsLTguMmwtMiwwbDAsN2wyLDBsMCwtN3oiIGZpbGw9IiNBQjI2RkIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgLz48L2c+PC9zdmc+);
  background-repeat: repeat;
  background-position: center right;
  background-size: calc(var(--sk-space-24) + var(--sk-space-8));
}

.PageContent_funFact__NsDpR .PageContent_image__FFYsl {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  rotate: -90deg;
}

/* Skills */
.PageContent_skills__TGxse .PageContent_cards__HZcQn {
  margin-top: var(--sk-space-24);
}

.PageContent_skills__TGxse .PageContent_card__vNeF7 {
  display: flex;
  flex-flow: column wrap;
  gap: var(--sk-space-16);
}

.PageContent_skills__TGxse ul {
  margin: 0;
  padding: 0 0 0 var(--sk-space-20);
}

/* Insight experiences */

.PageContent_insights__sc0tu .PageContent_head__hD589 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--sk-space-24);
  gap: var(--sk-space-16);
}

@media (min-width: 768px) {
  .PageContent_insights__sc0tu .PageContent_head__hD589 {
    flex-direction: row;
    align-items: center;
  }
}

.PageContent_insights__sc0tu .PageContent_list__7eJ__ {
  --local-column-count: 1;
  display: grid;
  grid-template-columns: repeat(var(--local-column-count), 1fr);
  margin: 0;
  padding: 0;

  list-style-type: none;
  gap: var(--sk-space-16);
}

/* 480px */

@media (min-width: 30.001rem) {
  .PageContent_insights__sc0tu .PageContent_list__7eJ__ {
    --local-column-count: 2;
  }
}

.PageContent_insights__sc0tu .PageContent_list__7eJ__ > article,
.PageContent_insights__sc0tu .PageContent_insightCard__6lC_z {
  height: 100%;
}

.PageContent_insights__sc0tu .PageContent_insightCard__6lC_z {
  --sk-data-display-card-body-gap: var(--sk-space-24);
}

/* Job Ads */
.PageContent_jobAds__Pw_HO {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: var(--sk-space-48) 0;
  gap: var(--sk-space-24);
}

.PageContent_jobAds--variant-light__U_s5W {
  background-color: var(--sk-color-grey-025);
}

.PageContent_jobAds--variant-dark___BjaW {
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
}

@media (min-width: 768px) {
  .PageContent_jobAds__Pw_HO {
    gap: var(--sk-space-40);
  }
}

.PageContent_jobAds__Pw_HO::before {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  width: 100vw;
  margin-left: 50%;
  background-color: inherit;
  inset: 0;
  translate: calc(-50% - 1px);
}

.PageContent_jobAds__Pw_HO .PageContent_head__hD589,
.PageContent_jobAds__Pw_HO .PageContent_list__7eJ__ {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
}

.PageContent_jobAds__Pw_HO .PageContent_head__hD589 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--sk-space-16);
}

@media (min-width: 768px) {
  .PageContent_jobAds__Pw_HO .PageContent_head__hD589 {
    flex-direction: row;
    align-items: center;
  }
}

.PageContent_jobAds__Pw_HO .PageContent_head__hD589 .PageContent_title__cgyx2 {
  margin-right: auto;
}

.PageContent_jobAds__Pw_HO .PageContent_list__7eJ__ {
  --local-gap: var(--sk-space-16);

  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: var(--local-gap)
}

@media (min-width: 768px) {
  .PageContent_jobAds__Pw_HO .PageContent_list__7eJ__ {
    grid-template-columns: repeat(2, minmax(calc(50% - var(--local-gap)), 1fr));
  }
}

@media (min-width: 1312px) {
  .PageContent_jobAds__Pw_HO .PageContent_list__7eJ__ {
    grid-template-columns: repeat(3, minmax(calc(33.33% - var(--local-gap)), 1fr));
  }
}

/* All job ads banner */
.PageContent_allJobAdsBanner__vV2vP {
  position: relative;
  padding: var(--sk-space-80) 0;
}

.PageContent_allJobAdsBanner__vV2vP::before {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  width: 100vw;
  margin-left: 50%;
  background-color: var(--sk-color-black);
  inset: 0;
  translate: calc(-50% - 1px);
}

.PageContent_allJobAdsBanner__vV2vP .PageContent_content__b0rr2 {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: var(--sk-space-32) var(--sk-space-48);
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
  gap: var(--sk-space-8);
}

.PageContent_allJobAdsBanner__vV2vP .PageContent_content__b0rr2::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--sk-size-48);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xhc3M9ImxheWVyIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xMC45NCw2LjYxYzAuOTcsLTAuNCAyLjAxLC0wLjYxIDMuMDYsLTAuNjFjMS4wNSwwIDIuMDksMC4yMSAzLjA2LDAuNjFjMC45NywwLjQgMS44NSwwLjk5IDIuNiwxLjczYzAuNzQsMC43NSAxLjMzLDEuNjMgMS43MywyLjZjMC40LDAuOTcgMC42MSwyLjAxIDAuNjEsMy4wNmMwLDEuMDUgLTAuMjEsMi4wOSAtMC42MSwzLjA2Yy0wLjI3LDAuNjYgLTAuNjMsMS4yOCAtMS4wNywxLjg1bDUuMzksNS4zOGMwLjM5LDAuMzkgMC4zOSwxLjAzIDAsMS40MmMtMC4zOSwwLjM5IC0xLjAzLDAuMzkgLTEuNDIsMGwtNS4zOCwtNS4zOWMtMC41NywwLjQ0IC0xLjE5LDAuOCAtMS44NSwxLjA3Yy0wLjk3LDAuNCAtMi4wMSwwLjYxIC0zLjA2LDAuNjFjLTEuMDUsMCAtMi4wOSwtMC4yMSAtMy4wNiwtMC42MWMtMC45NywtMC40IC0xLjg1LC0wLjk5IC0yLjYsLTEuNzNjLTAuNzQsLTAuNzUgLTEuMzMsLTEuNjMgLTEuNzMsLTIuNmMtMC40LC0wLjk3IC0wLjYxLC0yLjAxIC0wLjYxLC0zLjA2YzAsLTEuMDUgMC4yMSwtMi4wOSAwLjYxLC0zLjA2YzAuNCwtMC45NyAwLjk5LC0xLjg1IDEuNzMsLTIuNmMwLjc1LC0wLjc0IDEuNjMsLTEuMzMgMi42LC0xLjczem0zLjA2LDEuMzljLTAuNzksMCAtMS41NywwLjE2IC0yLjMsMC40NmMtMC43MiwwLjMgLTEuMzksMC43NCAtMS45NCwxLjNjLTAuNTYsMC41NSAtMSwxLjIyIC0xLjMsMS45NGMtMC4zLDAuNzMgLTAuNDYsMS41MSAtMC40NiwyLjNjMCwwLjc5IDAuMTYsMS41NyAwLjQ2LDIuM2MwLjMsMC43MiAwLjc0LDEuMzkgMS4zLDEuOTRjMC41NSwwLjU2IDEuMjIsMSAxLjk0LDEuM2MwLjczLDAuMyAxLjUxLDAuNDYgMi4zLDAuNDZjMC43OSwwIDEuNTcsLTAuMTYgMi4zLC0wLjQ2YzAuNzIsLTAuMyAxLjM5LC0wLjc0IDEuOTQsLTEuM2MwLjU2LC0wLjU1IDEsLTEuMjIgMS4zLC0xLjk0YzAuMywtMC43MyAwLjQ2LC0xLjUxIDAuNDYsLTIuM2MwLC0wLjc5IC0wLjE2LC0xLjU3IC0wLjQ2LC0yLjNjLTAuMywtMC43MiAtMC43NCwtMS4zOSAtMS4zLC0xLjk0Yy0wLjU1LC0wLjU2IC0xLjIyLC0xIC0xLjk0LC0xLjNjLTAuNzMsLTAuMyAtMS41MSwtMC40NiAtMi4zLC0wLjQ2eiIgZmlsbD0iI0NCQzlDRiIgZmlsbC1ydWxlPSJldmVub2RkIiAvPjwvZz48L3N2Zz4=);
  background-repeat: repeat;
  background-position: center right;
  background-size: calc(var(--sk-space-24) + var(--sk-space-8));
}

.PageContent_allJobAdsBanner__vV2vP .PageContent_image__FFYsl {
  position: absolute;
  top: calc(-1 * var(--sk-space-72));
  right: calc(-1 * var(--sk-space-80));
  pointer-events: none;
  scale: .5;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media (min-width: 768px) {
  .PageContent_allJobAdsBanner__vV2vP .PageContent_image__FFYsl {
    top: calc(-1 * var(--sk-space-40));
    right: calc(-1 * var(--sk-space-72));
    scale: 1;
  }
}

.PageContent_allJobAdsBanner__vV2vP a {
  margin-top: var(--sk-space-8);
}



/* Feedback */
.PageContent_feedback__Cctav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sk-space-24) var(--sk-space-16);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  gap: var(--sk-space-24);
}

.PageContent_feedback__Cctav .PageContent_title__cgyx2 {
  margin: 0 auto 0 0;
}

.PageContent_feedback__Cctav .PageContent_title__cgyx2:first-child:last-child {
  width: 100%;
  text-align: center;
}

/* Breadcrumb */
.PageContent_breadcrumb__4fUj5 {
  display: flex;
  justify-content: center;
}
}
@layer base {
  .RelatedPages_title__dwhLV, .RelatedPages_subtitle__AOtIq {
  margin-bottom: var(--sk-space-24);
}

.RelatedPages_title__dwhLV:has(+ .RelatedPages_subtitle__AOtIq),
.RelatedPages_list__IHK25:has(+ .RelatedPages_subtitle__AOtIq) {
  margin-bottom: var(--sk-space-40);
}

.RelatedPages_list__IHK25 {
  --local-column-count: 1;
  display: grid;
  grid-template-columns: repeat(var(--local-column-count), 1fr);
  margin: 0;
  padding: 0;

  list-style-type: none;
  gap: var(--sk-space-16);
}

/* 480px */

@media (min-width: 30.001rem) {
  .RelatedPages_list__IHK25 {
    --local-column-count: 2;
  }
}

.RelatedPages_linkCard__eA0ka {
  display: flex;
  box-sizing: border-box;
  align-content: space-between;
  align-items: center;
  height: 100%;
}

.RelatedPages_link__P3egJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-16);
}

.RelatedPages_link__P3egJ span {
  flex: 1;
}
}
@layer base {
  .LinkCard_main__4Xd4u {
  --sk-data-display-card-body-content-gap: var(--sk-space-8);
  --sk-data-display-card-body-margin-right: var(--sk-space-64);
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  position: relative;
  padding: var(--sk-space-24);

  transition: border 100ms ease-out;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}


.LinkCard_main__4Xd4u a {
  outline: none;
  color: inherit;
  text-decoration: none;
}

.LinkCard_main__4Xd4u a::after {
  content: "";
  position: absolute;
  /* place it above the seen filter */
  z-index: var(--z-up-in-the-current-stacking-context);
  inset: 0;
}

.LinkCard_main__4Xd4u a:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.LinkCard_main__4Xd4u a:focus-visible::after {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);

  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}
}
@layer base {
  .InsightAdditionModal_main__hD44l {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.InsightAdditionModal_footer__H5FJk {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 767px) {
  .InsightAdditionModal_footer__H5FJk {
    flex-direction: column-reverse wrap;
  }
}

.InsightAdditionModal_empty__sKX_O {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sk-space-16);
}

.InsightAdditionModal_empty__sKX_O .InsightAdditionModal_description__1yhjl {
  color: var(--sk-color-grey-400);
}

.InsightAdditionModal_list__O50XG {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-16);
}

.InsightAdditionModal_linkCard__4aY1c {
  display: flex;
  box-sizing: border-box;
  align-content: space-between;
  align-items: center;
  height: 100%;
}

.InsightAdditionModal_link__4eYvC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-16);
}

.InsightAdditionModal_link__4eYvC div {
  flex: 1;
}
}
@layer base {
  .CardContent_main__qm4MT {
  display: flex;
  align-items: center;
}

/* Avatar */
.CardContent_avatar__JqCz3 {
  height: var(--sk-space-48);
  padding-right: var(--sk-space-8);
}

.CardContent_imgProfilePicture__Lkw_y {
  -o-object-fit: cover;
     object-fit: cover;
}

/* Texts */

.CardContent_textContent__Wwl_u {
  margin-right: auto;
}

.CardContent_secondaryText__D1rml {
  color: var(--sk-color-grey-500);
}

}
@layer base {
  .CardWrapper_main__jCbr4,
.CardWrapper_main__jCbr4:link,
.CardWrapper_main__jCbr4:visited {
    display: block;
    padding: var(--sk-space-12) var(--sk-space-16);
    border: var(--sk-space-2) solid var(--sk-color-black);
    border-radius: var(--sk-radius-8);
    color: inherit;
    text-decoration: none;
}

.CardWrapper_main__jCbr4:is(a):hover {
    background: var(--sk-color-dark-10);
}

.CardWrapper_main__jCbr4:is(a):focus-visible {
    outline: var(--sk-space-2) solid var(--sk-action-button-color-border-secondary-default-light);
    outline-offset: calc(-1 * var(--sk-space-2));
    background-color: var(--sk-action-button-color-background-secondary-focus-light);
    box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
    color: var(--sk-action-button-color-secondary-focus-light);
}

.CardWrapper_main__jCbr4:is(a):active {
    background: var(--sk-color-dark-20);
}

}
@layer base {
  .Dropdown_main__rq3Iq {
  position: absolute;
  z-index: var(--z-up-in-the-current-stacking-context);
  box-sizing: border-box;
  width: 100%;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-8) 0;
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
  box-shadow: 0 13px 60px 0 rgb(54 46 63 / 10%);
}

}
@layer base {
  .LocaleSwitcher_triggerButton__mQ0bd {
  flex-direction: row-reverse;
  outline-color: var(--sk-color-grey-100);
}

.LocaleSwitcher_dropdown__7Bjzn {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 1;
  right: 0;
  width: auto;
  max-height: 32vh;
  overflow-y: auto;
}

.LocaleSwitcher_localesList__qzkdc {
  margin: 0;
  padding: var(--sk-space-4) var(--sk-space-12);
  list-style: none;
}

.LocaleSwitcher_localeButton__Y0tJ2 {
  display: flex;
  flex-direction: row;
  gap: var(--sk-space-8);
  align-items: center;
  justify-content: flex-start;
}

}
@layer base {
  .ClickableCard_trash__Obki3 {
  margin-left: var(--sk-space-32);
  color: var(--sk-color-error-500);
}

.ClickableCard_reload__JjL3B {
  margin-left: var(--sk-space-32);
}

}
@layer base {
  .LocationFilter_main__A768n {
  --icon-size: var(--sk-size-20);
  --field-padding: var(--sk-space-16);
  position: relative;
  width: 100%;
  height: 48px;
}

.LocationFilter_main--radius-enabled__jS36_ .LocationFilter_main__inputContainer__fEEjK {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.LocationFilter_main__pinIcon____nc6 {
  position: absolute;
  left: var(--field-padding);
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  color: var(--sk-color-black);
  pointer-events: none;
}

.LocationFilter_main__clearButton__cBiHs {
  position: absolute;
  right: var(--field-padding);
  align-self: center;
  width: var(--sk-size-24);
  height: var(--sk-size-24);
  padding: 0;
  border: none;
  border-radius: var(--sk-radius-8);
  outline: none;
  background-color: transparent;
  color: var(--sk-color-grey-200);
  line-height: 0;
  cursor: pointer;
}

.LocationFilter_main__clearButton__cBiHs:active,
.LocationFilter_main__clearButton__cBiHs:focus {
  background-color: var(--sk-color-grey-050);
  color: var(--sk-color-black);
}

.LocationFilter_main__clearButton__cBiHs:hover {
  color: var(--sk-color-black);
}

.LocationFilter_main__inputContainer__fEEjK {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 0 0 var(--sk-radius-16) var(--sk-radius-16);
  background-color: var(--sk-color-white);
}

.LocationFilter_main__input__B11T6:hover,
.LocationFilter_main__input__B11T6:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
}

.LocationFilter_main__input__B11T6:focus {
  background-color: var(--sk-color-grey-050);
  cursor: text;
}

.LocationFilter_main__input__B11T6 {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-right: calc(var(--field-padding) + var(--icon-size) + var(--sk-space-12));
  padding-left: calc(var(--field-padding) + var(--icon-size) + var(--sk-space-12));
  overflow: hidden;
  border: 0;
  outline: none;
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-overflow: ellipsis;
}

.LocationFilter_main__input__B11T6::-moz-placeholder {
  -moz-transition: 0.2s;
  transition: 0.2s;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.LocationFilter_main__input__B11T6::placeholder {
  transition: 0.2s;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-space-16);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.LocationFilter_main__input__B11T6:focus::-moz-placeholder {
  opacity: 0;
}

.LocationFilter_main__input__B11T6:focus::placeholder {
  opacity: 0;
}

.LocationFilter_main__input__B11T6[type="search"]::-webkit-search-cancel-button,
.LocationFilter_main__input__B11T6[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
          appearance: none;
}

@media (min-width: 768px) {
  .LocationFilter_main__A768n {
    flex: 1;
    height: 56px;
  }

  .LocationFilter_main__inputContainer__fEEjK {
    border-radius: 0 var(--sk-radius-16) var(--sk-radius-16) 0;
  }
}

}
@layer base {
  .CoverLetter_main____MPD {
  margin-top: var(--sk-space-24);
}

@media (min-width: 1312px) {
  .CoverLetter_main____MPD {
    margin-top: var(--sk-space-32);
  }
}

.CoverLetter_addNewFile__BTa_O {
  width: 100%;
  margin-top: var(--sk-space-8);
}

.CoverLetter_error__ja_HS {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
  text-align: center;
}

.CoverLetter_header__2gKu8 {
  margin-bottom: var(--sk-space-16);
}

.CoverLetter_cvOnlyDescription__QWQsk {
  display: flex;
  align-items: center;
  padding: var(--sk-space-16) var(--sk-space-12);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  gap: var(--sk-space-16);
}

.CoverLetter_cvOnlyDescription__icon__qJetC {
  flex-shrink: 0;
}

}
@layer base {
  .AppLocationFilter_main__S79eI {
  position: relative;
}

.AppLocationFilter_dropdownMessage__tsXBH {
  padding: var(--sk-space-8) var(--sk-space-16);
  color: var(--sk-color-grey-500);
}

.AppLocationFilter_dropdownSuggestion__He8x8 {
  width: 100%;
  padding: var(--sk-space-8) var(--sk-space-16);
  border: none;
  background-color: var(--sk-color-white);
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-poppins);
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

.AppLocationFilter_dropdownSuggestion__He8x8:hover,
.AppLocationFilter_dropdownSuggestion__He8x8:focus,
.AppLocationFilter_dropdownSuggestion__He8x8:active {
  outline: none;
  background-color: var(--sk-color-grey-050);
  cursor: pointer;
}
}
@layer base {
  .PhoneNumber_main__tJnS9 {
  --inputsGap: var(--sk-space-8);
  --wideCountryCodeWidth: 280px;

  display: flex;
  flex-wrap: wrap;
  gap: var(--inputsGap);
}

.PhoneNumber_labelWrapper__KN7BW {
  display: flex;
  flex: 1 0 100%;
  gap: var(--sk-space-4);
  align-items: center;
}

.PhoneNumber_label__xTRw3 {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.PhoneNumber_subLabel__96_03 {
  color: var(--sk-form-text-input-secondary-label-color-light);
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.PhoneNumber_countryCode__e6jZN {
  width: 100%;
}

.PhoneNumber_countryNumber__L4Qeo {
  flex: 1;
}

.PhoneNumber_countryNumberInner__PA1eW {
  color: var(--sk-color-grey-600);
  font-size: var(--sk-typography-body-2-regular-font-size);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.PhoneNumber_countryNumberInner__PA1eW::before {
  content: var(--dialCode); /* dialCode custom properties is set by javascript */
  display: inline-block;
  margin-right: var(--sk-space-8);
}

.PhoneNumber_main__tJnS9.js-wideContainer {
  .PhoneNumber_countryCode__e6jZN {
    width: var(--wideCountryCodeWidth);
  }

  .PhoneNumber_countryNumber__L4Qeo {
    width: calc(100% - var(--wideCountryCodeWidth) - var(--inputsGap));
  }
}

}
@layer base {
  .Form_form__34yHi {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}
}
@layer base {
  .SocialMedia_fieldsList__4lzsL {
  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
}

.SocialMedia_fieldItem__YL8Cr {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-16);
  gap: var(--sk-space-16);
}

.SocialMedia_field__OJGgD {
  flex-grow: 1;
}

.SocialMedia_missingMediaList__XvQ14 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-8);
  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
}

}
@layer base {
  .PersonalInformationForm_main__lwVhQ {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.PersonalInformationForm_fieldsContainer__TeX1E {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--sk-space-24);
}

.PersonalInformationForm_divider___krVd {
  margin: var(--sk-space-24) 0;
}

.PersonalInformationForm_buttonsContainer__L2M_H {
  display: flex;
}

.PersonalInformationForm_saveButton__taqhT {
  flex-grow: 1;
}

@media (max-width: 767px) {
  .PersonalInformationForm_buttonsContainer__L2M_H {
    margin: auto calc(-1 * var(--sk-space-16)) calc(-1 * var(--sk-space-16));
    padding: var(--sk-space-24) var(--sk-space-16) var(--sk-space-32);
    background-color: var(--sk-color-white);
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) 0 rgba(100 96 108 / 15%);
  }
}

@media (min-width: 768px) {
  .PersonalInformationForm_buttonsContainer__L2M_H {
    margin-top: var(--sk-space-16);
  }
}

@media (min-width: 1312px) {
  .PersonalInformationForm_fieldsContainer__TeX1E {
    grid-template-columns: 1fr 1fr;
    gap: var(--sk-space-16);
  }

  .PersonalInformationForm_phoneNumber__YSURH {
    grid-column: span 2;
    --inputsGap: var(--sk-space-16);
    --wideCountryCodeWidth: calc(50% - calc(var(--inputsGap) / 2));
  }

  .PersonalInformationForm_buttonsContainer__L2M_H {
    justify-content: flex-end;
  }

  .PersonalInformationForm_saveButton__taqhT {
    flex-grow: initial;
  }
}

}
@layer base {
  .AdditionalProfileFields_fieldsWrapper__UGpwY {
  display: flex;
  flex-direction: column;
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-16);
}

.AdditionalProfileFields_submitButton__ikiFI {
  width: 100%;
}

}
@layer base {
  .Profile_header__mq2hM {
  margin-bottom: var(--sk-space-16);
}

}
@layer base {
  .SecondaryFiltersModal_main__iasjS {
  margin-bottom: var(--sk-size-240);
}

.SecondaryFiltersModal_divider__kgG4o {
  margin: var(--sk-space-24) 0 var(--sk-space-32);
}

@media (min-width: 768px) {
  .SecondaryFiltersModal_divider__kgG4o {
    margin: var(--sk-space-32) 0 var(--sk-space-48);
  }
}

.SecondaryFiltersModal_footer__5Bvtf {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .SecondaryFiltersModal_footer__5Bvtf {
    flex-direction: column-reverse wrap;
  }
}

@media (max-width: 767px) {
  .SecondaryFiltersModal_filter__Wlebe:first-child {
    margin-top: var(--sk-space-24);
  }
}

.SecondaryFiltersModal_filter__location__ytxs9 label,
.SecondaryFiltersModal_filter__location__ytxs9 label span {
  font-size: var(--sk-typography-title-3-font-size);
  font-weight: var(--sk-typography-body-1-bold-font-weight);
  line-height: var(--sk-typography-title-3-line-height);
}
}
@layer base {
  .PrimaryFilters_main__37DWR {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: transparent;
}

.PrimaryFilters_main__37DWR [class*="jds-ValueContainer"] {
  max-width: 85%;
}

@media (min-width: 768px) {
  .PrimaryFilters_main__37DWR {
    flex-direction: row;
    --field-height: 56px;
    width: 100%;
  }
}

.PrimaryFilters_primaryFilter__qT94w {
  flex: 1;
  min-width: 0;
}

.PrimaryFilters_searchInput__GKb0a {
  flex-grow: 1;
  margin: 0;
}

.PrimaryFilters_searchInputContainer__k8IoD {
  height: 100%;
  border: none;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"],
.PrimaryFilters_searchInputContainer__k8IoD {
  height: var(--field-height);
  margin: 0;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: var(--sk-radius-16) var(--sk-radius-16) 0 0 !important;
  border-color: transparent !important;
  outline: 0 !important;
  text-overflow: ellipsis;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:-moz-placeholder-shown, .PrimaryFilters_searchInputContainer__k8IoD:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:placeholder-shown,
.PrimaryFilters_searchInputContainer__k8IoD:placeholder-shown {
  text-overflow: ellipsis;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:hover,
.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:focus,
.PrimaryFilters_searchInputContainer__k8IoD:hover,
.PrimaryFilters_searchInputContainer__k8IoD:focus {
  background-color: var(--sk-color-grey-050) !important;
}

.PrimaryFilters_searchInput__GKb0a [class*="jds-Input"]:focus {
  background-color: var(--sk-color-grey-050) !important;
}

.PrimaryFilters_contractFilter__pwaol {
  height: var(--field-height);
  max-height: var(--field-height);
}

.PrimaryFilters_contractFilter__pwaol > button {
  border: none;
  border-radius: 0;
}

@media (min-width: 768px) {
  .PrimaryFilters_searchInput__GKb0a [class*="jds-Input"],
  .PrimaryFilters_searchInputContainer__k8IoD {
    flex-grow: 1;
    border-radius: var(--sk-radius-16) 0 0 var(--sk-radius-16) !important;
  }

  .PrimaryFilters_searchInput__GKb0a [class*="jds-Input"] {
    border-radius: var(--sk-radius-16) 0 0 var(--sk-radius-16) !important;
  }
}

@media (min-width: 1312px) {
  .PrimaryFilters_searchInput__GKb0a {
    flex-grow: 2;
  }
}

@media (max-width: 63.999rem) {
  .PrimaryFilters_searchInput__GKb0a {
    flex-grow: 1;
  }
}

}
@layer base {
  .CardBackdrop_backdrop__AcEOl {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
  animation: 0.2s ease-out 0s 1 CardBackdrop_show-backdrop__WZCIV;
  opacity: 0.2;
  background-color: var(--sk-color-black);
}

@keyframes CardBackdrop_show-backdrop__WZCIV {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

}
@layer base {
  .CardEdit_main__hH2Kf {
  display: flex;
  position: relative;
  /* to be above the backdrop */
  z-index: calc(var(--z-index-modal) + 1);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-24);
  border: 2px solid var(--sk-color-black);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  gap: var(--sk-space-24);
}

.CardEdit_header__PAjdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CardEdit_content__rVAAP {
  display: flex;
  gap: var(--sk-space-24);
}

.CardEdit_deleteBtn__UzK0g {
  --sk-action-button-color-background-primary-default-light: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-default-dark: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-hover-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-hover-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-light: var(--sk-color-error-550);
}

.CardEdit_actions__cW1dW {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-24);
}

.CardEdit_actions__cW1dW > div {
  display: flex;
  gap: var(--sk-space-16);
}

.CardEdit_actions__cW1dW .CardEdit_confirmContainer__LqzUb {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .CardEdit_avatar__hrL_9 {
    display: none;
  }

  .CardEdit_actions__cW1dW {
    display: flex;
    flex-direction: column-reverse;
    gap: var(--sk-space-16);
  }

  .CardEdit_actions__cW1dW > div {
    width: 100%;
  }

  .CardEdit_deleteBtn__UzK0g,
  .CardEdit_confirmBtn__FJCo0 {
    width: 100%;
  }
}

}
@layer base {
  .Card_main__bj3pb {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-16) 0;
  transition: background-color 150ms ease-in-out;
  border-radius: var(--sk-radius-8);
  cursor: pointer;
}

.Card_main__bj3pb:hover,
.Card_main__bj3pb:focus-within {
  background-color: var(--sk-color-grey-050);
}

.Card_container__rw0sr {
  display: flex;
  position: relative;
  justify-content: flex-start;
  gap: var(--sk-space-16);
}

.Card_title__07JlQ {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card_subtitle__DBPfK {
  color: var(--sk-color-grey-700);
}

.Card_content__BzLck {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-24);
}

.Card_content__BzLck > div:first-child {
  height: 100%;
}

.Card_tag__NqLIJ {
  margin-top: var(--sk-space-42);
}

.Card_edit__G_8Gj {
  position: absolute;
  top: calc(var(--sk-space-8) * -1);
  right: 0;
}

.Card_footer__MdiUO {
  padding: var(--sk-space-24);
  border-top: 1px solid var(--sk-color-grey-100);
}

@media (min-width: 768px) {
  .Card_main__bj3pb {
    padding: var(--sk-space-24);
  }

  .Card_edit__G_8Gj {
    display: none;
  }

  .Card_main__bj3pb:hover .Card_edit__G_8Gj,
  .Card_main__bj3pb:focus-within .Card_edit__G_8Gj {
    display: block;
  }

  .Card_tag__NqLIJ {
    min-width: 7rem;
  }
}

@media (max-width: 767px) {
  .Card_container__rw0sr {
    padding-right: var(--sk-space-64);
  }

  .Card_content__BzLck {
    flex-direction: column;
    align-items: flex-start;
  }
}

}
@layer base {
  .LanguageCard_form__7akVg {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--sk-space-16);
}

.LanguageCard_select__Qjy6Z {
  width: 100%;
}

@media (min-width: 768px) {
  .LanguageCard_form__7akVg {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .LanguageCard_select__Qjy6Z {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    flex-direction: column;
  }
}

}
@layer ui {
  .sk-Radio_radioWrapper__VAn8i {
  position: relative;
}

.sk-Radio_main__lxi9G {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-12);
  transition: background-color 100ms ease-out;
  border-radius: var(--sk-form-radio-radius);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Radio_main--highlighted__qfqjX {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: var(--sk-space-16);
  border: solid var(--sk-border-width-2);
  gap: var(--sk-space-16);
}

.sk-Radio_main--light__WgOWW.sk-Radio_main--highlighted__qfqjX {
  border-color: var(--sk-form-radio-color-border-highlighted-unchecked-light);
}

.sk-Radio_main--dark__AkgrO.sk-Radio_main--highlighted__qfqjX {
  border-color: var(--sk-form-radio-color-border-highlighted-unchecked-dark);
}

input:checked + .sk-Radio_main--light__WgOWW.sk-Radio_main--highlighted__qfqjX:not(.sk-Radio_main--disabled__u_dXa) {
  border-color: var(--sk-form-radio-color-border-highlighted-checked-light);
}

input:checked + .sk-Radio_main--light__WgOWW.sk-Radio_dark--highlighted__reh2C:not(.sk-Radio_main--disabled__u_dXa) {
  border-color: var(--sk-form-radio-color-border-highlighted-checked-dark);
}

.sk-Radio_hiddenRadioInput__XQh2f {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
}

.sk-Radio_radio__O_M_P {
  display: inline-flex;
  box-sizing: border-box;
  flex-shrink: 0;
  place-items: center center;
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  margin: var(--sk-space-2) 0;
  border-radius: var(--sk-form-radio-input-radius);
}

.sk-Radio_label__CwO9w {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-Radio_main--highlighted__qfqjX .sk-Radio_label__CwO9w {
  font-family: var(--sk-typography-body-1-regular-font-family);
  font-size: var(--sk-typography-body-1-regular-font-size);
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.sk-Radio_main--disabled__u_dXa {
  cursor: not-allowed;
}

.sk-Radio_main--light__WgOWW {
  color: var(--sk-form-radio-color-default-light);
}

.sk-Radio_main--dark__AkgrO {
  color: var(--sk-form-radio-color-default-dark);
}

.sk-Radio_main--light__WgOWW:hover:not(.sk-Radio_main--disabled__u_dXa) {
  background-color: var(--sk-form-radio-color-background-hover-light);
  color: var(--sk-form-radio-color-hover-light);
}

.sk-Radio_main--dark__AkgrO:hover:not(.sk-Radio_main--disabled__u_dXa) {
  background-color: var(--sk-form-radio-color-background-hover-dark);
  color: var(--sk-form-radio-color-hover-dark);
}

input:focus-visible + .sk-Radio_main--light__WgOWW {
  background-color: var(--sk-form-radio-color-background-focus-light);
  color: var(--sk-form-radio-color-focus-light);
}

input:focus-visible + .sk-Radio_main--dark__AkgrO {
  background-color: var(--sk-form-radio-color-background-focus-dark);
  color: var(--sk-form-radio-color-focus-dark);
}

.sk-Radio_main--light__WgOWW.sk-Radio_main--disabled__u_dXa {
  color: var(--sk-form-radio-color-disabled-light);
}

.sk-Radio_main--dark__AkgrO.sk-Radio_main--disabled__u_dXa {
  color: var(--sk-form-radio-color-disabled-dark);
}

input:checked + .sk-Radio_main__lxi9G .sk-Radio_radio__O_M_P::after {
  content: "";
  display: flex;
  flex-shrink: 0;
  width: var(--sk-size-12);
  height: var(--sk-size-12);
  margin: 0 auto;
  animation: sk-Radio_expandCircle__GxXyw 100ms forwards;
  border-radius: 50%;
  opacity: 0;
}

input:focus-visible + .sk-Radio_main__lxi9G {
  outline: none;
  box-shadow: var(--sk-shadow-focus-ring-light-shadow-3), var(--sk-shadow-focus-ring-light-shadow-2),
    var(--sk-shadow-focus-ring-light-shadow-1);
}

.sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P {
  border: var(--sk-size-2) solid var(--sk-form-radio-input-color-border-default-light);
  background-color: transparent;
}

.sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P {
  border: var(--sk-size-2) solid var(--sk-form-radio-input-color-border-default-dark);
  background-color: transparent;
}

.sk-Radio_main--light__WgOWW:hover:not(.sk-Radio_main--disabled__u_dXa) .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-hover-light);
  background-color: transparent;
}

.sk-Radio_main--dark__AkgrO:hover:not(.sk-Radio_main--disabled__u_dXa) .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-hover-dark);
  background-color: transparent;
}

input:focus-visible + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-focus-light);
  background-color: transparent;
}

input:focus-visible + .sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-focus-dark);
  background-color: transparent;
}

.sk-Radio_main--light__WgOWW.sk-Radio_main--disabled__u_dXa .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-disabled-light);
  background-color: transparent;
}

.sk-Radio_main--dark__AkgrO.sk-Radio_main--disabled__u_dXa .sk-Radio_radio__O_M_P {
  border-color: var(--sk-form-radio-input-color-border-disabled-dark);
  background-color: transparent;
}

input:checked + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-default-light);
}

input:checked + .sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-default-dark);
}

.sk-Radio_main--light__WgOWW:hover:not(.sk-Radio_main--disabled__u_dXa) .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-hover-light);
}

.sk-Radio_main--dark__AkgrO:hover:not(.sk-Radio_main--disabled__u_dXa).sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-hover-dark);
}

input:focus-visible + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-focus-light);
}

input:focus-visible + .sk-Radio_main--dark__AkgrO.sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-focus-dark);
}

input:checked:disabled + .sk-Radio_main--light__WgOWW .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-disabled-light);
}

input:checked:disabled + .sk-Radio_main--dark__AkgrO .sk-Radio_radio__O_M_P::after {
  background-color: var(--sk-form-radio-input-color-disabled-dark);
}

@keyframes sk-Radio_expandCircle__GxXyw {
  from {
    transform: scale(0.2);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

}
@layer ui {
  .sk-RadioGroup_main__D6VW3 {
  display: flex;
  flex-direction: column;
}

.sk-RadioGroup_main--highlighted__2jsSc {
  gap: var(--sk-space-8);
}

.sk-RadioGroup_main__D6VW3 > :first-child {
  margin-bottom: var(--sk-space-8);
}

.sk-RadioGroup_errorMessage__UBPUC {
  padding: var(--sk-space-2) var(--sk-space-12);
  color: var(--sk-color-error-500);
}

.sk-RadioGroup_main--light__f3_IH {
  color: var(--sk-form-radio-color-default-light);
}

.sk-RadioGroup_main--dark__egxe6 {
  color: var(--sk-form-radio-color-default-dark);
}

}
@layer base {
  /* Layout grid: parent */
.Layout_layout__69T2U {
  --local-row-gap: var(--sk-space-8);
  --local-column-gap: var(--sk-space-8);
  --local-horizontal-margin: var(--sk-space-16);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-right: var(--local-horizontal-margin);
  padding-left: var(--local-horizontal-margin);
  gap: var(--local-row-gap) var(--local-column-gap);
}

.Layout_layout__69T2U.Layout_variant_4cols__5GP6o,
.Layout_layout__69T2U.Layout_variant_3cols__Yn4Gi {
  grid-template-columns: 100%;
}

@media (min-width: 768px) {
  .Layout_layout__69T2U {
    --local-row-gap: var(--sk-space-16);
    --local-column-gap: var(--sk-space-16);
    --local-horizontal-margin: var(--sk-space-40);
    grid-template-columns: repeat(12, 1fr);
    max-width: calc(12 * var(--local-column-width) + 11 * var(--local-column-gap));
  }

  .Layout_layout__69T2U.Layout_variant_4cols__5GP6o,
  .Layout_layout__69T2U.Layout_variant_3cols__Yn4Gi {
    grid-template-columns: repeat(2, minmax(calc(50% - var(--local-column-gap)), 1fr));
  }
}

@media (min-width: 1312px) {
  .Layout_layout__69T2U {
    --local-column-width: 88px;
    --local-horizontal-margin: 0;
    position: relative;
    grid-template-columns: repeat(12, var(--local-column-width));
    width: 100%;
    max-width: calc(12 * var(--local-column-width) + 11 * var(--local-column-gap));
    margin-right: auto;
    margin-left: auto;
  }

  .Layout_layout__69T2U.Layout_variant_4cols__5GP6o {
    grid-template-columns: repeat(4, minmax(calc(25% - var(--local-column-gap)), 1fr));
  }

  .Layout_layout__69T2U.Layout_variant_3cols__Yn4Gi {
    grid-template-columns: repeat(3, minmax(calc(33.33% - var(--local-column-gap)), 1fr));
  }
}

/* Layout grid: children */
.Layout_layout__69T2U > .Layout_layout__child___full__30n5A,
.Layout_layout__69T2U > .Layout_layout__child___large__4qQ8n,
.Layout_layout__69T2U > .Layout_layout__child___medium__5hrkg {
  grid-column: 1 / span 4;
}

@media (min-width: 768px) {
  .Layout_layout__69T2U > .Layout_layout__child___full__30n5A {
    grid-column: 1 / span 12;
  }

  .Layout_layout__69T2U > .Layout_layout__child___large__4qQ8n {
    grid-column: 2 / span 10;
  }

  .Layout_layout__69T2U > .Layout_layout__child___medium__5hrkg {
    grid-column: 3 / span 8;
  }
}

@media (min-width: 1312px) {
  .Layout_layout__69T2U > .Layout_layout__child___large__4qQ8n {
    grid-column: 3 / span 8;
  }

  .Layout_layout__69T2U > .Layout_layout__child___medium__5hrkg {
    grid-column: 4 / span 6;
  }
}

/* Layout grid: custom children */
.Layout_layout__69T2U > .Layout_layout__child___custom__3AD_A {
  grid-column: 1 / span 4;
}

@media (min-width: 768px) {
  .Layout_layout__69T2U > .Layout_layout__child___custom__3AD_A {
    grid-column: var(--layout-child-from) / var(--layout-child-to);
  }
}
}
@layer base {
  .RatingDisplay_main__HLXwV {
  --local-icon-size: var(--sk-space-24);
  position: relative;

  margin: 0;
  overflow: hidden;
}

.RatingDisplay_main__HLXwV figcaption {
  visibility: hidden;
}

.RatingDisplay_main__HLXwV::before,
.RatingDisplay_main__HLXwV::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: currentcolor;
}

.RatingDisplay_main__HLXwV::before {
  -webkit-mask-image: var(--filled-src);
          mask-image: var(--filled-src);
  left: calc(var(--local-icon-size) * -5);
}

.RatingDisplay_main__HLXwV::after {
  -webkit-mask-image: var(--outlined-src);
          mask-image: var(--outlined-src);
}

.RatingDisplay_main__HLXwV,
.RatingDisplay_main__HLXwV::before,
.RatingDisplay_main__HLXwV::after {
  width: calc(var(--local-icon-size) * 5);
  height: var(--local-icon-size);
}

.RatingDisplay_rating__0__GrhT0 {
  color: var(--sk-color-grey-100);
}

.RatingDisplay_rating__1__VmBK_::before {
  left: calc(var(--local-icon-size) * -4);
}

.RatingDisplay_rating__1__VmBK_::after {
  left: calc(var(--local-icon-size));
}

.RatingDisplay_rating__2__3hV8a::before {
  left: calc(var(--local-icon-size) * -3);
}

.RatingDisplay_rating__2__3hV8a::after {
  left: calc(var(--local-icon-size) * 2);
}

.RatingDisplay_rating__3__X1RZ2::before {
  left: calc(var(--local-icon-size) * -2);
}

.RatingDisplay_rating__3__X1RZ2::after {
  left: calc(var(--local-icon-size) * 3);
}

.RatingDisplay_rating__4__jTgr_::before {
  left: calc(var(--local-icon-size) * -1);
}

.RatingDisplay_rating__4__jTgr_::after {
  left: calc(var(--local-icon-size) * 4);
}

.RatingDisplay_rating__5__jPvJ_::before {
  left: 0;
}

.RatingDisplay_rating__5__jPvJ_::after {
  left: calc(var(--local-icon-size) * 5);
}

}
@layer base {
  /* Test styles */
.layouts_wrapper__frcQH {
  padding: var(--sk-space-16) 0;
  background-color: var(--sk-color-grey-100);
}

.layouts_placeholder__A_rRX {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: var(--sk-color-grey-200);
}

.layouts_wrapper__frcQH hr {
  margin: var(--sk-space-8) var(--sk-space-16);
}

@media (min-width: 768px) {
  .layouts_wrapper__frcQH hr {
    margin: var(--sk-space-16) var(--sk-space-40);
  }
}

@media (min-width: 1312px) {
  .layouts_wrapper__frcQH hr {
    max-width: 1232px;
    margin: var(--sk-space-16) auto;
  }
}

}
@layer base {
  .Services_servicesWrapper__FoyTZ {
  margin-bottom: var(--sk-space-48);
  text-align: center;
}

.Services_servicesTitle__M1_TY {
  margin-bottom: var(--sk-space-16);
}

.Services_serviceColumnList__H7syf {
  display: grid;
  grid-gap: var(--sk-space-8);
  grid-template-columns: auto;
  margin-bottom: var(--sk-space-16);
  padding: 0;
}

.Services_serviceItem__xIyHc {
  display: flex;
  align-items: center;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-16);
  background: var(--sk-color-grey-025);
  text-align: start;
}

.Services_icon__rwL9D {
  flex-shrink: 0;
  margin-right: var(--sk-space-8);
  color: var(--sk-color-purple-500);
}

@media (min-width: 768px) {
  .Services_servicesTitle__M1_TY {
    margin-bottom: var(--sk-space-32);
  }

  .Services_serviceColumnList__H7syf {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1312px) {
  .Services_servicesWrapper__FoyTZ {
    margin-bottom: var(--sk-space-80);
  }

  .Services_servicesTitle__M1_TY {
    margin-bottom: var(--sk-space-40);
  }
}

}
@layer base {
  .Nav_main__2MlMc {
  position: relative;
}

.Nav_main__2MlMc::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(90deg, rgb(246 246 249 / 0%) 20%, #ffffff 100%);
}

.Nav_wrapper__zXuln {
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
}

.Nav_list__tkcvn {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: var(--sk-space-24);
}

.Nav_link__5hyRm {
  display: flex;
  align-items: center;
  padding-bottom: var(--sk-space-16);
  color: var(--sk-color-black);
  text-decoration: none;
  white-space: nowrap;
}

.Nav_link__5hyRm:hover,
.Nav_link__current__4Agmw {
  border-bottom: 4px solid #000000;
}

.Nav_link__current__4Agmw {
  font-weight: 600;
}
}
@layer base {
  .PageContent_main__OH_VM {
  --local-column-gap: var(--sk-space-24);
  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-space-32);
}

@media (min-width: 768px) {
  .PageContent_main__OH_VM {
    padding-top: var(--sk-size-88);
  }
}

.PageContent_header__KfuBG {
  display: flex;
  gap: var(--sk-space-24);
  flex-direction: column;
}
}
@layer base {
  .PageContent_main__1a3nS {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--sk-space-64) 0;
  text-align: center;
  gap: var(--sk-space-16);
}

.PageContent_main__1a3nS img {
  max-width: 75%;
  height: auto;
  margin-bottom: var(--sk-space-32);
}

@media (min-width: 768px) {
  .PageContent_main__1a3nS img {
    max-width: inherit;
  }
}

}
@layer base {
  .Feedbacks_list__rqAq4 {
  display: flex;
  flex-direction: column;
  gap: var(--sk-size-24);
  margin-top: var(--sk-size-24);
}

.Feedbacks_feedback__Bo4UO {
  display: flex;
  flex-direction: column;
  gap: var(--sk-size-12);
}
}
@layer base {
  .Header_main__zK0bv {
  display: flex;
  flex-direction: column;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-grey-025);
  gap: var(--sk-space-48);
}

@media (min-width: 768px) {
  .Header_main__zK0bv {
    flex-direction: row;
    gap: var(--sk-space-56);
    padding: var(--sk-space-40) 0;
  }
}

.Header_user__COVJ2, .Header_company__rH9tb {
  flex: 1;
}

@media (min-width: 768px) {
  .Header_user__COVJ2 {
    padding: 0 var(--sk-space-40);
  }
}

.Header_user__COVJ2 .Header_badges__dOFz_ {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-12);
}

.Header_user__COVJ2 .Header_badge__OUrB6 {
  align-self: flex-start;
}

.Header_user__COVJ2 .Header_badge__OUrB6::first-letter,
.Header_user__COVJ2 .Header_badge__OUrB6 ::first-letter {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .Header_user__COVJ2 .Header_badges__dOFz_ {
    flex-direction: row;
  }
}

.Header_user__COVJ2 .Header_title__VFfQb {
  margin-top: var(--sk-space-16);
  margin-bottom: var(--sk-space-40);
}

.Header_user__COVJ2 .Header_avatar__hDaZO {
  align-self: flex-start;
}

.Header_user__COVJ2 .Header_avatar__hDaZO img {
  width: auto;
}

.Header_user__COVJ2 .Header_details__Nepda {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-4);
}

.Header_user__COVJ2 .Header_details__Nepda .Header_details___userContact__Mmz7N {
  align-self: flex-start;
  margin-top: var(--sk-space-16);
}

.Header_company__rH9tb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--sk-space-24);
  border-top: 2px solid var(--sk-color-grey-100);
  gap: var(--sk-space-8);
}

@media (min-width: 768px) {
  .Header_company__rH9tb {
    padding-top: 0;
    border-top: none;
    border-left: 2px solid var(--sk-color-grey-100);
  }
}

.Header_company__rH9tb .Header_illustration__COcs8 {
  margin-bottom: var(--sk-space-4);
  border-radius: var(--sk-radius-8);
}


}
@layer base {
  .PageContent_main__p_nw2 {
  --local-row-gap: var(--sk-space-40) !important;
  margin-top: var(--sk-space-40);

  margin-bottom: var(--sk-space-40);
}

@media (min-width: 768px) {
  .PageContent_main__p_nw2 {
    --local-row-gap: var(--sk-space-80) !important;
    margin-top: var(--sk-size-88);

    margin-bottom: var(--sk-size-88);
  }
}

.PageContent_sectionTitle__oDmc2 {
  margin-bottom: var(--sk-space-24);
}

.PageContent_globalFeedbacks__GjAqu {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-40);
}

.PageContent_globalFeedback__Co_9J {
  padding-right: var(--sk-space-56);
}

.PageContent_companyFeedbacks__2ZfMQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--sk-space-24);
  gap: var(--sk-space-24);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  text-align: center;
}

.PageContent_companyFeedbacks___item__ghQob,
.PageContent_companyFeedbacks___item__ghQob > div {
  align-items: center;
  justify-content: center;
}

.PageContent_companyFeedbacks___item__ghQob:not(:first-child) {
  position: relative;
  padding-top: var(--sk-space-24);
}

.PageContent_companyFeedbacks___item__ghQob:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: var(--sk-size-40);
  transform: translateX(-50%);
  border-top: 1px solid var(--sk-color-grey-100);
}

.PageContent_footer__RNafN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-24);
}
}
@layer base {
  .PageFooter_main__vBfXx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--sk-space-56);
}

/* Main actions */
.PageFooter_mainActions__RkyOQ {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  row-gap: var(--sk-space-16);
}

.PageFooter_mainActions__button__fvepb {
  width: 100%;
}

@media (min-width: 768px) {
  .PageFooter_mainActions__RkyOQ {
    grid-auto-flow: column;
    width: auto;
    -moz-column-gap: var(--sk-space-32);
         column-gap: var(--sk-space-32);
  }

  .PageFooter_mainActions__button__fvepb {
    width: auto;
  }
}

.PageFooter_actionsForDesktop___e02Q {
  display: none;
}

@media (min-width: 768px) {
  .PageFooter_main__vBfXx {
    margin-top: var(--sk-space-80);
  }

  .PageFooter_mainActions__RkyOQ {
    display: none;
  }

  .PageFooter_actionsForDesktop___e02Q {
    display: flex;
    gap: var(--sk-space-16);
  }
}

/* the footer actions are NOT displayed on the printed version of the page */

@media print {
  .PageFooter_main__vBfXx {
    display: none;
  }
}

}
@layer base {
  .CompanyInfo_wrapperLogo__Qzw7E {
  height: var(--sk-size-64);
}

.CompanyInfo_signature__x_yHr {
  padding: var(--sk-space-24) 0;
}

.CompanyInfo_signature__x_yHr > picture {
  width: var(--sk-size-64);
  height: var(--sk-size-64);
  margin-right: var(--sk-size-4);
  transition: box-shadow 300ms ease-out;
  border-radius: var(--sk-radius-8);
  box-shadow: 0 2px 12px 0 rgb(217 217 217 / 50%);
}

.CompanyInfo_signature__x_yHr > picture:hover {
  border-radius: var(--sk-radius-8);
  box-shadow: 0 2px 12px 0 rgb(217 217 217 / 100%);
}

.CompanyInfo_link__3qSmH > picture:hover {
  cursor: pointer;
}

.CompanyInfo_content__PfMOG {
  overflow: hidden; /* Usefull to apply text-overflow to his div child */
}

.CompanyInfo_description__koAY3 {
  color: var(--sk-color-grey-700);
}

.CompanyInfo_description__item___desktopOnly__hZCVT {
  display: none;
}

@media (min-width: 768px) {
  .CompanyInfo_description__koAY3 {
    overflow: hidden;
    color: var(--sk-color-grey-700);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .CompanyInfo_description__item__gGmQA {
    display: inline;
  }

  .CompanyInfo_description__item___desktopOnly__hZCVT {
    display: initial;
  }

  .CompanyInfo_description__item___withSeparator__8HlId::before {
    content: "•";
    margin: 0 var(--sk-space-4);
  }
}

}
@layer base {
  .PageHeader_main__xix2W {
  padding-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageHeader_main__xix2W {
    margin-top: var(--sk-space-40);
  }
}

.PageHeader_publicationDate__xucPI {
  padding-bottom: var(--sk-space-24);
  color: var(--sk-color-grey-600);
}

@media print {
  /* decrease spacing with candidacy details on the printed version of the page */

  .PageHeader_main__xix2W {
    padding-bottom: var(--sk-space-4);
  }
}

}
@layer base {
  /**
 Layout
*/

.PageLayout_wrapper__vpNNU {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

/* TODO: provide a props to FoLayout which manage the background kind (grey or white) */
.PageLayout_foLayout__L4XtS {
  background-color: var(--sk-color-white);
}

.PageLayout_contentLayout__kv1BQ {
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageLayout_contentLayout__kv1BQ {
    padding-bottom: var(--sk-space-80);
  }
}

@media (min-width: 1312px) {
  .PageLayout_contentLayout__kv1BQ {
    position: relative;
    transition: transform 0.5s;
  }

  .PageLayout_contentLayout___withAside__04szM {
    transform: translateX(-208px); /* (88px * 2) + (16px * 2)  */
  }
}

}
@layer base {
  /* visual style of the slidebar when it is visible */

@media (max-width: 767px) {
  .SlideBarCandidateActions_main__FRA0V {
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: var(--sk-space-16);
    background-color: var(--sk-color-white);
    /* shadow is --sk-color-grey-600 with 15% opacity */
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) 0 rgba(100 96 108 / 15%);
  }
}

@media (min-width: 768px) {
  .SlideBarCandidateActions_main__FRA0V {
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: center;
    height: 0;
  }

  .SlideBarCandidateActions_contentBox___eo6v {
    position: relative;
    /* height of the element + padding bottom to not touch the bottom of the screen */
    bottom: calc(var(--sk-space-48) + var(--sk-space-16));
  }
}

/* management of progressive appearance and disappearance of the slidebar */

.SlideBarCandidateActions_main___appearing__PydPZ {
  animation: SlideBarCandidateActions_fade-in-from-none__W2W0N 0.5s;
}

.SlideBarCandidateActions_main___disappearing___PEZI {
  animation: SlideBarCandidateActions_fade-out-to-none__cu89u 0.5s forwards;
}

@keyframes SlideBarCandidateActions_fade-in-from-none__W2W0N {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes SlideBarCandidateActions_fade-out-to-none__cu89u {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* the scroll bar should not be present on the printed version of the page */

@media print {
  .SlideBarCandidateActions_main__FRA0V {
    display: none;
  }
}

}
@layer base {
  .SchoolBadge_main__v5iU7 {
  display: inline-flex;
  align-items: center;
  height: 26px;
  border: 1px solid var(--sk-color-grey-200);
  border-radius: var(--sk-radius-8);
}

.SchoolBadge_label__wg2oB {
  padding: 0 var(--sk-space-8) 0 var(--sk-space-8);
  color: var(--sk-color-grey-700);
}

.SchoolBadge_logo__j6kwN {
  margin-left: var(--sk-space-4);
  border-radius: var(--sk-radius-4);
}

}
@layer base {
  .TopBadges_main__LS3o2 {
  margin-bottom: var(--sk-space-24);
}

.TopBadges_main__LS3o2, .TopBadges_secondary__5zD9I {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sk-space-16) var(--sk-space-8);
}

}
@layer base {
  .Card_main__odW37 {
  padding: var(--sk-space-16) 0;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.Card_title__46mwY {
  margin-bottom: var(--sk-space-8);
}

@media (min-width: 768px) {
  .Card_main__odW37 {
    padding: var(--sk-space-16) var(--sk-space-24);
    border-radius: var(--sk-space-8);
    background: var(--sk-color-grey-050);
  }
}

}
@layer base {
  .Summary_main__DPlYe {
  margin-top: var(--sk-space-56);
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .Summary_main__DPlYe {
    display: grid;
    grid-auto-columns: 1fr;
    gap: var(--sk-space-24);
    margin-top: var(--sk-space-80);
  }

  .Summary_item__T15UW {
    grid-row: 1;
  }
}

}
@layer base {
  .TagsSection_main__MBqRI {
  display: flex;
  flex-wrap: wrap;
  margin: 0; /* reset browsers style */
  padding: 0; /* reset browsers style */
  list-style: none; /* reset browsers style */
  gap: var(--sk-space-8);
}

}
@layer base {
  .PrivateOfferButton_main__AbTB_ {
  width: 100%;
}

}
@layer base {
  /* Aside */
.Aside_aside__wPMHD {
  display: none;
}

.Aside_aside___isPrefilteredJobSearchPage__Nr5xF,
.Aside_aside___isRssFeedVisible__1NU_a,
.Aside_aside___isPrivateOfferButtonVisible__n1EdD {
  display: initial;
}

.Aside_aside___hasNotSavedSearches__M76LO {
  padding-top: calc(var(--sk-space-48) + var(--sk-space-12));
}

.Aside_aside__divider__mIdhH {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-32);
}

.Aside_aside__divider__mIdhH:first-child {
  display: none;
}

@media (max-width: 767px) {
  .Aside_aside__wPMHD {
    margin-top: var(--sk-space-32);
  }

  .Aside_aside__wPMHD .Aside_savedSearches__jZgAi,
  .Aside_aside__wPMHD .Aside_savedSearches__jZgAi + .Aside_aside__divider__mIdhH {
    display: none;
  }
}

@media (min-width: 768px) {
  .Aside_aside__wPMHD {
    display: initial;
  }
}

}
@layer base {
  .PageContent_main___O7aK {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--sk-space-32);
  background-color: var(--sk-color-grey-025);
}

.PageContent_primaryFilters__j52b2 {
  /*
    With this z-index, this flex item will be above the secondary filters
    that are positioned and after in the order of appearance in the HTML.
    current stacking context : filtersContainer
  */
  z-index: var(--z-up-in-the-current-stacking-context);
}

.PageContent_results__zSSNO {
  display: flex;
  flex-direction: column;
  margin: var(--sk-space-12) 0 var(--sk-space-32);
  padding: 0;
  list-style-type: none;
  gap: var(--sk-space-16);
}

.PageContent_saveSearchButton__l2KC6 {
  display: flex;
  position: sticky;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  bottom: var(--sk-space-24);
  left: 0;
  justify-content: center;
  width: 100%;
  margin-top: var(--sk-space-32);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .PageContent_saveSearchButton__l2KC6 {
    width: auto;
  }

  .PageContent_saveSearchButton___isSaveSearchButtonInSearchBarEnabled__jeqAl {
    display: none;
  }

  .PageContent_illustration__2V73p {
    z-index: var(--z-up-in-the-current-stacking-context) !important;
    top: var(--sk-space-64) !important;
    right: var(--sk-space-40) !important;
    transform: rotate(0deg) !important;
  }
}

@media (min-width: 1312px) {
  .PageContent_illustration__2V73p {
    top: var(--sk-space-16) !important;
    right: var(--sk-space-24) !important;
  }
}

}
@layer base {
  .PublicationDateFilterShortcut_main__oJ4ny {
  position: relative;
}

@media (max-width: 767px) {
  .PublicationDateFilterShortcut_main__oJ4ny {
    display: none;
  }
}

.PublicationDateFilterShortcut_dropdown__jMS_l {
  padding: var(--sk-space-4) var(--sk-space-8);
}

}
@layer base {
  .ResultsSort_main__T1eJo {
  /*
    These position and z-index stack this element and its descendants above all these positioned elements:
    - the job ad cards
  */
  position: relative;
  z-index: var(--z-above-ui-kit-cards);
}

@media (max-width: 767px) {
  /* Make it a square button with no extra space */
  .ResultsSort_button__6PeyO {
    width: var(--sk-size-48);
    height: var(--sk-size-48);
    padding-right: var(--sk-Button-x-padding);
  }
}

@media (min-width: 768px) {
  /* Display the label of the button */
  .ResultsSort_button__6PeyO::after {
    content: attr(aria-label);
  }
}

.ResultsSort_button___expanded__BUuzY {
  background-color: var(--sk-action-button-color-background-tertiary-hover-light);
}

.ResultsSort_options__HWPlp {
  position: absolute;
  right: 0;
  margin-top: var(--sk-space-8);
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-white);
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(0 0 0 / 25%);
}

.ResultsSort_options__HWPlp label {
  white-space: nowrap;
}

}
@layer base {
  .RssFeedModal_button__14UF6 {
  margin-left: var(--sk-space-4);
}

}
@layer base {
  .RssFeed_main__3nlsA {
  margin-top: var(--sk-space-16);
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-12);
  background: var(--sk-color-white);
}

.RssFeed_header__3hNB6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RssFeed_button__4Gvkl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--sk-space-8);
}

.RssFeed_error__mAdGl {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
  text-align: center;
}

.RssFeed_field__GZm3A {
  margin-top: var(--sk-space-16);
  margin-bottom: var(--sk-space-16);
}

.RssFeed_field__GZm3A input {
  min-width: 0;
}

}
@layer base {
  /* Main */
.SecondaryFilters_main__IUPre {
  width: 100%;
}

@media (min-width: 768px) {
  .SecondaryFilters_main__IUPre {
    width: auto;
  }
}

/* Actions */
.SecondaryFilters_actions__XAKs8 {
  display: grid;
  justify-items: center;
}

@media (min-width: 768px) {
  .SecondaryFilters_actions__XAKs8 {
    justify-items: flex-start;
  }
}

/* Button */

@media (max-width: 767px) {
  .SecondaryFilters_button__UZTC_ {
    width: 100%;
  }
}

}
@layer base {
  .SecondaryFiltersBar_main__1PyDK {
  --gap-size: var(--sk-space-8);
  display: flex;
  gap: var(--gap-size);
}

.SecondaryFiltersBar_divider__dDszn {
  align-self: center;
  height: var(--sk-space-24);
  transform: scaleY(50%);
}

.SecondaryFiltersBar_divider__dDszn,
.SecondaryFiltersBar_shortcutFilter__rP2XA {
  display: none;
}

@media (max-width: 767px) {
  .SecondaryFiltersBar_saveSearchButton__r5sMf {
    display: none;
  }
}

.SecondaryFiltersBar_saveSearchButton__r5sMf {
  margin-left: auto;
}

@media (min-width: 1312px) {
  .SecondaryFiltersBar_divider__dDszn,
  .SecondaryFiltersBar_shortcutFilter__rP2XA {
    display: initial;
  }
}

}
@layer base {
  .Page_main__4K54e {
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
  background-color: var(--sk-color-grey-025);
}

.Page_cards__bLJG_ {
  --local-column-count: 1;
  display: grid;
  gap: var(--sk-space-16);
  grid-template-columns: repeat(var(--local-column-count), minmax(0, 1fr));
}


@media (min-width: 768px) {
  .Page_cards__bLJG_ {
    --local-column-count: 2;
  }
}

@media (min-width: 1312px) {
  .Page_cards__bLJG_ {
    --local-column-count: 3;
  }
}

.Page_card__HcRyZ {
  position: relative;
  overflow: visible;
  border-color: var(--sk-color-grey-100);
}

.Page_card__link__4ebuQ {
  outline: 0;
  color: inherit;
  text-decoration: none;
}

.Page_card__link__4ebuQ::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: var(--z-up-in-the-current-stacking-context);
}

.Page_card__link__4ebuQ:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-data-display-card-color-border-default-light) 2px solid;
}

.Page_card__link__4ebuQ:focus-visible::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

}
@layer base {
  .Page_main__uXvqu {
  --local-row-gap: var(--sk-space-24);
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

.Page_mainContent__cao5N {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--sk-space-24);
}

@media (min-width: 768px) {
  .Page_main__uXvqu {
    padding-top: var(--sk-space-64);
    padding-bottom: var(--sk-space-64);
  }

  .Page_aside__McIFH {
    margin-right: var(--sk-space-16);
  }
}

}
@layer base {
  .PageContent_main__J2sE4 {
  flex: 1;
  padding: var(--sk-space-24) 0;
  background-color: var(--sk-color-grey-025);
}

.PageContent_content__yOjQe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  padding: var(--sk-space-24);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
}

.PageContent_notice__TthIP {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  color: var(--sk-color-grey-800);
  gap: var(--sk-space-16);
}

.PageContent_notice__TthIP .PageContent_icon__i4wO_ {
  color: var(--sk-color-purple-500);
}

.PageContent_notice__TthIP span {
  flex: 1;
}
}
@layer base {
  .PageContent_main__WUrH3 {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.PageContent_content__bqDHJ {
  --local-row-gap: var(--sk-space-32) !important;
  padding: var(--sk-space-40) var(--sk-space-16);
}

@media (min-width: 768px) {
  .PageContent_content__bqDHJ {
    padding: var(--sk-space-64) 0;
  }
}

.PageContent_form__TLZQi {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_form__TLZQi {
    padding: var(--sk-space-48) var(--sk-space-32);
    border-radius: var(--sk-radius-16);
    background-color: var(--sk-color-white);
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(0 0 0 / 25%);
    gap: var(--sk-space-40);
  }
}

.PageContent_formActions__IIck8 {
  display: flex;
  justify-content: center;
  margin-top: var(--sk-space-16);
}

.PageContent_formActions__IIck8 button {
  width: 100%;
}
}
@layer base {
  .NewPageContent_main__mxh1l {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
  margin-top: var(--sk-space-16);
}

@media (min-width: 768px) {
  .NewPageContent_main__mxh1l {
    grid-column: 1 / -1;
    margin-top: var(--sk-space-56);
  }
}

}
@layer base {
  .PageContent_main__lBKFk {
  display: flex;
  grid-column: 1 / 5;
  flex-direction: column;
  gap: var(--sk-space-40);
}

.PageContent_layout__iLeNN {
  --local-row-gap: var(--sk-space-40) !important;
}

.PageContent_rightColumn__k6dYe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-48);
}

.PageContent_section__QFTta {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.PageContent_headingContainer__K3myO {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageContent_block__QEM_k {
  height: 300px;
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-green-200);
}

@media (min-width: 768px) {
  .PageContent_main__lBKFk {
    grid-column: 1 / 9;
    padding-right: var(--sk-space-12);
  }

  .PageContent_layout__iLeNN {
    --local-row-gap: var(--sk-space-80) !important;
  }

  .PageContent_newDashboardMain__nnMXk {
    grid-column: 1 / -1;
  }
}

@media (min-width: 1312px) {
  .PageContent_rightColumn__k6dYe {
    margin-left: calc(var(--sk-space-72) - var(--local-column-gap));
  }
}

@media (min-width: 80rem) {
  .PageContent_main__lBKFk {
    grid-column: 4 / 10;
    padding: 0 var(--sk-space-24);
  }

  .PageContent_rightColumn__k6dYe {
    margin-left: var(--sk-space-56);
  }
}

@media (max-width: 767px) {
  .PageContent_layout__iLeNN > :first-child {
    order: 1;
  }

  .PageContent_layout__iLeNN > :nth-child(2) {
    order: 3;
  }

  .PageContent_layout__iLeNN > :last-child {
    order: 2;
  }
}

}
@layer base {
  .Pagination_main__J10Ng {
  display: flex;
  justify-content: center;
}

.Pagination_item__Raak6 {
  box-sizing: border-box;
  min-width: calc(var(--sk-size-40) + var(--sk-size-4));
  min-height: calc(var(--sk-size-40) + var(--sk-size-4));
  padding: 0;
  font-weight: var(--sk-typography-body-1-regular-font-weight);
  text-align: center;
}

.Pagination_item___ellipsis__aWI89 {
  min-width: inherit;
  margin: 0 var(--sk-size-4);
  padding-top: var(--sk-size-8);
}

.Pagination_item___current__V_VJj {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
  pointer-events: none;
}

@media (max-width: 409px) {
  .Pagination_item___ellipsis__aWI89 ~ .Pagination_item___current_close_sibling__RY3Xf:not(.Pagination_item___first__65cnq, .Pagination_item___last__feZeg) {
    display: none;
  }
}

@media (max-width: 767px) {
  .Pagination_item___current_sibling__aq2dX:not(.Pagination_item___ellipsis__aWI89, .Pagination_item___current_close_sibling__RY3Xf, .Pagination_item___first__65cnq, .Pagination_item___last__feZeg) {
    display: none;
  }
}

@media (min-width: 768px) {
  .Pagination_item___mobile_ellipsis__LrXst {
    display: none;
  }
}

}
@layer base {
  .Layout_main__a82xA {
  --local-row-gap: var(--sk-space-16);

  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-size-40);
}

.Layout_main__a82xA.Layout_main__primary__1Ze0f:has(+ .Layout_main__a82xA) {
  padding-bottom: 0;
}

.Layout_main__a82xA.Layout_main__primary__1Ze0f + .Layout_main__a82xA {
  padding-top: var(--sk-space-40);
}

.Layout_main__a82xA.Layout_main__secondary__B2mXw + .Layout_main__a82xA {
  padding-top: var(--sk-space-32);
}

.Layout_main__a82xA.Layout_main__secondary__B2mXw {
  position: relative;
}

.Layout_main__a82xA.Layout_main__secondary__B2mXw::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context);
  top: 0;
  bottom: 0;
  width: 100vw;
  margin-left: 50%;
  background-color: var(--sk-color-black);
  translate: -50%;
}

@media (min-width: 768px) {
  .Layout_main__a82xA {
    --local-row-gap: var(--sk-space-24);

    padding-top: var(--sk-size-88);
    padding-bottom: var(--sk-size-88);
  }

  .Layout_main__a82xA.Layout_main__primary__1Ze0f + .Layout_main__a82xA {
    padding-top: var(--sk-size-56);
  }

  .Layout_main__a82xA.Layout_main__secondary__B2mXw + .Layout_main__a82xA {
    padding-top: var(--sk-space-64);
  }
}

.Layout_head__PKPe0 {
  display: flex;
  flex-direction: column;
}

.Layout_subtitle__kgtiM {
  margin-top: var(--sk-space-16);
}

.Layout_description__GlowJ {
  margin-top: var(--sk-space-8);
}

.Layout_illustration__eOIWS {
  display: none;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media (min-width: 768px) {
  .Layout_illustration__eOIWS {
    display: block;
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context);
    top: var(--sk-size-32);
    right: 0;
  }
}

.Layout_filters__UY09H {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  flex-direction: column;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  gap: var(--sk-space-16);
}

.Layout_results__vb5JA {
  display: flex;
  position: relative;
  z-index: var(--z-reset-in-the-current-stacking-context);
  flex-direction: column;
  gap: var(--sk-space-24);
}

.Layout_results--loading__blspS {
  animation: Layout_results-loading__NxFnP 1s linear infinite alternate;
  color: currentcolor;
}

@keyframes Layout_results-loading__NxFnP {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

}
@layer base {
  .Pagination_main--loading__q7C11 {
  cursor: not-allowed;
}

.Pagination_main--loading__q7C11 a,
.Pagination_main--loading__q7C11 button
 {
  pointer-events: none;
}
}
@layer base {
  .State_main__aJBNo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 364px;
  margin-right: auto;
  margin-left: auto;
  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-space-40);
}

.State_icon__vNRht {
  width: var(--sk-size-104);
  height: var(--sk-size-104);
}

.State_title__azYHv,
.State_description__hu2ph {
  text-align: center;
}

.State_title__azYHv {
  margin-top: var(--sk-space-8);
}

.State_description__hu2ph {
  margin-top: var(--sk-space-8);
}

}
@layer base {
  /* LAYOUT */

@media (min-width: 768px) {
  .PageContent_illustration__XFs3W {
    display: none;
  }
}

@media (min-width: 1312px) {
  .PageContent_illustration__XFs3W {
    display: block;
    margin-top: var(--sk-space-24);
    transform: rotate(15deg);
  }
}

/* RESULTS */
.PageContent_resultsHeader__34bSy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageContent_resultsItems__tHkds {
  --local-horizontal-margin: 0 !important;
  --local-row-gap: var(--sk-space-24) !important;

  margin-top: 0;
  margin-bottom: var(--sk-space-16);
}

.PageContent_resultsItem__rzGHk {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.PageContent_resultsItem__rzGHk > article,
.PageContent_resultsItem__rzGHk .PageContent_card__LGXYm {
  height: 100%;
}

}
@layer base {
  .PageContent_main__LfuZ5 {
  overflow: hidden;
}

.PageContent_main__LfuZ5 + div {
  z-index: var(--z-up-in-the-current-stacking-context);
  background-color: var(--sk-color-white);
}

@media (min-width: 1312px) {
  .PageContent_main__LfuZ5 {
    overflow: visible;
  }
}

.PageContent_illustration__j0RY4 {
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context);
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  scale: 0.33;
}

.PageContent_illustration--cv__qmfDA {
  rotate: 15deg;
  top: -138px;
  left: -168px;
}

.PageContent_illustration--info__QM19D {
  rotate: 15deg;
  top: -80px;
  right: -110px;
}

.PageContent_illustration--search__bBQ9p {
  rotate: 15deg;
  bottom: -160px;
  left: 60px;
}

.PageContent_illustration--stats__HyhLe {
  right: 0;
  bottom: -40px;
  rotate: -15deg;
}

@media (min-width: 768px) {
  .PageContent_illustration__j0RY4 {
    scale: .5;
  }
}

@media (min-width: 1312px) {
  .PageContent_illustration__j0RY4 {
    scale: 1;
  }

  .PageContent_illustration--cv__qmfDA {
    top: -88px;
    left: -128px;
  }

  .PageContent_illustration--info__QM19D {
    top: -75px;
    right: -50px;
  }

  .PageContent_illustration--search__bBQ9p {
    bottom: -88px;
    left: 0;
  }

  .PageContent_illustration--stats__HyhLe {
    right: 90px;
    bottom: 0;
  }
}

.PageContent_headline__KmiiN {
  z-index: var(--z-up-in-the-current-stacking-context);
  padding-right: var(--sk-space-24);
  padding-left: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_headline__KmiiN {
    padding: 0;
  }
}

}
@layer base {
  .ExperienceViewCard_main__W_7Fw {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-16) 0;
  transition: background-color 150ms ease-in-out;
  border-radius: var(--sk-radius-8);
  cursor: pointer;
}

.ExperienceViewCard_main__W_7Fw:hover,
.ExperienceViewCard_main__W_7Fw:focus-within {
  background-color: var(--sk-color-grey-050);
}

.ExperienceViewCard_container__YTeUq {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--sk-space-16);
}

.ExperienceViewCard_header__pMYGF {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

.ExperienceViewCard_overtitle__X8Y_t {
  color: var(--sk-color-grey-700);
}

.ExperienceViewCard_titleContainer__WWVVS {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

.ExperienceViewCard_title__tG6A3 {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ExperienceViewCard_subtitle__dP4Oe {
  color: var(--sk-color-grey-700);
}

.ExperienceViewCard_content__8FJYE {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-24);
}

.ExperienceViewCard_content__8FJYE > div:first-child {
  height: 100%;
}

.ExperienceViewCard_description__XRZfH {
  margin-top: var(--sk-space-16);
  white-space: pre-wrap;
}

.ExperienceViewCard_tag__MBphB {
  margin-top: var(--sk-space-42);
}

.ExperienceViewCard_tag--desktop__BvhiP {
  display: none;
}

.ExperienceViewCard_edit__ZYQRH {
  position: absolute;
  top: calc(var(--sk-space-8) * -1);
  right: 0;
}

.ExperienceViewCard_footer__nNoqe {
  margin-top: var(--sk-space-16);
  padding: var(--sk-space-16) var(--sk-space-24);
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-12);
}

@media (min-width: 768px) {
  .ExperienceViewCard_main__W_7Fw {
    padding: var(--sk-space-24);
  }

  .ExperienceViewCard_edit__ZYQRH {
    display: none;
  }

  .ExperienceViewCard_main__W_7Fw:hover .ExperienceViewCard_edit__ZYQRH,
  .ExperienceViewCard_main__W_7Fw:focus-within .ExperienceViewCard_edit__ZYQRH {
    display: block;
  }

  .ExperienceViewCard_tag__MBphB {
    min-width: 7rem;
  }

  .ExperienceViewCard_tag--desktop__BvhiP {
    display: block;
  }

  .ExperienceViewCard_tag--mobile__mTV7x {
    display: none;
  }
}

@media (max-width: 767px) {
  .ExperienceViewCard_container__YTeUq {
    flex-direction: column;
    padding-right: var(--sk-space-64);
  }

  .ExperienceViewCard_content__8FJYE {
    flex-direction: column;
    align-items: flex-start;
  }
}

}
@layer base {
  .MainCourseViewCard_avatar__fe0XS {
  min-width: var(--sk-Avatar-size);
}

}
@layer base {
  .OtherCourseViewCard_avatar__P9A5_ {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.OtherCourseViewCard_avatar__P9A5_ img {
  -o-object-fit: contain;
     object-fit: contain;
}
}
@layer base {
  .GuidanceToolCard_main__vRCFp {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
  padding: var(--sk-space-16) var(--sk-space-24);
  transition: background-color ease-in-out 150ms;
  border-radius: var(--sk-space-12);
  color: inherit;
  text-decoration: none;
}

.GuidanceToolCard_main__vRCFp:hover,
.GuidanceToolCard_main__vRCFp:focus {
  background-color: var(--sk-color-grey-050);
}

.GuidanceToolCard_content__MyaTo {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  gap: var(--sk-space-16);
}

.GuidanceToolCard_testNameAndTime__UHs8g {
  display: flex;
  gap: var(--sk-space-16);
  align-items: center;
  margin-bottom: var(--sk-space-8);
}

.GuidanceToolCard_chevron__Lphqk {
  min-width: var(--sk-size-24);
}

@media (min-width: 768px) {
  .GuidanceToolCard_main__vRCFp {
    flex-direction: row;
  }
}

}
@layer base {
  .GuidanceToolsSection_list__CLJOM {
  margin: 0;
  padding: 0;
}

.GuidanceToolsSection_list__item__7lbvt {
  margin: 0;
  padding: var(--sk-space-16) 0;
  list-style: none;
}

.GuidanceToolsSection_list__item__7lbvt:first-child {
  padding-top: 0;
}

.GuidanceToolsSection_list__item__7lbvt:not(:last-child) {
  border-bottom: 1px solid var(--sk-color-dark-20);
}

.GuidanceToolsSection_list__item__7lbvt:last-child {
  padding-bottom: 0;
}

}
@layer base {
  .CardListEmptyState_main__9pkQS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-16);
  max-width: 390px;
  margin-right: auto;
  margin-left: auto;
  text-wrap: balance;
}

.CardListEmptyState_description__ghyOq {
  color: var(--sk-color-grey-400);
  text-align: center;
}

}
@layer base {
  .OtherExperienceViewCard_avatar__s0sNB {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.OtherExperienceViewCard_avatar__s0sNB img {
  -o-object-fit: contain;
     object-fit: contain;
}
}
@layer base {
  .SocialMedia_main__lop6v {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--sk-space-8);
}

.SocialMedia_link___reset__RtyzF {
  color: inherit;
  text-decoration: none;
}

}
@layer base {
  .StudiesInformation_list__B24K7 {
  padding: 0;
}

.StudiesInformation_item__4OWOe {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.StudiesInformation_item__4OWOe:not(:last-child)::after {
  content: ' • ';
}

}
@layer base {
  .PersonalInformationSection_main__3WhgV {
  padding: var(--sk-space-24);
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
}

.PersonalInformationSection_editLinkWrapper__VyUgP {
  display: flex;
  justify-content: end;
}

.PersonalInformationSection_information__0Xp7x {
  margin-top: calc(-1 * var(--sk-space-48));
  padding: 0 var(--sk-space-56);
  text-align: center;
}

.PersonalInformationSection_profilePictureWrapper__vyZ2s {
  display: flex;
  position: relative;
  justify-content: center;
}

.PersonalInformationSection_profilePicture__aI1vB {
  -o-object-fit: cover;
     object-fit: cover;
}

.PersonalInformationSection_careerCenterLogo__ZeMcJ {
  position: absolute;
  right: 0;
  bottom: 0;
}

.PersonalInformationSection_name____sk4 {
  margin-top: var(--sk-space-20);
}

.PersonalInformationSection_studiesInformation__kktTk {
  margin-top: var(--sk-space-4);
}

.PersonalInformationSection_contactInformation__pSa0B {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-12);
}

.PersonalInformationSection_socialMedia__dJ9e6 {
  margin-top: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PersonalInformationSection_information__0Xp7x {
    margin-top: calc(-1 * var(--sk-space-32));
  }
}

}
@layer base {
  .Languages_main__4rkwJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--sk-space-16);
  gap: var(--sk-space-16);
}

@media (min-width: 768px) {
  .Languages_main___emptyState__8aura {
    margin-bottom: var(--sk-space-24);
  }
}

.Languages_addLanguageButton__xw7D8 {
  width: -moz-fit-content;
  width: fit-content;
}

}
@layer base {
  .ListPage_list__bv_9R {
  padding: 0;
}

.ListPage_list__item__5jDT7 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ListPage_list__item__5jDT7:not(:last-child) {
  margin-bottom: var(--sk-space-24);
}

.ListPage_addButton__sGFSp {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

}
@layer base {
  .ListPage_list__yrgQN {
  padding: 0;
}

.ListPage_list__item__ao2jP {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ListPage_list__item__ao2jP:not(:last-child) {
  margin-bottom: var(--sk-space-24);
}

.ListPage_addButton__g_y4t {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

}
@layer base {
  .SeeMore_wrapper__9neUF {
  display: flex;
  align-items: flex-end;
}

.SeeMore_main__YPjlq {
  --SeeMore-text-length: unset;
  display: -webkit-box;
  overflow: hidden;
  color: unset;
  -webkit-line-clamp: var(--SeeMore-text-length);
  line-clamp: var(--SeeMore-text-length);
  -webkit-box-orient: vertical;
}

.SeeMore_main--expanded__aFhk2 {
  display: block;
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.SeeMore_main--expanded__aFhk2 + .SeeMore_seeMoreButton__MIUVZ {
  display: none;
}

.SeeMore_seeMoreButton__MIUVZ {
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-decoration: underline;
  cursor: pointer;
}

}
@layer base {
  .WorkExperienceViewCard_avatar__fOfEh {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.WorkExperienceViewCard_avatar__fOfEh img {
  -o-object-fit: contain;
     object-fit: contain;
}

.WorkExperienceViewCard_footer__y7VXe {
  display: none;
}

@media (min-width: 768px) {
  .WorkExperienceViewCard_footer__y7VXe {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--sk-space-16);
  }

  .WorkExperienceViewCard_footer__action__zOTFu {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

@media (min-width: 1312px) {
  .WorkExperienceViewCard_footer__y7VXe {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--sk-space-48);
  }
}

}
